import React from 'react'

export const Mosaic = (props) => {
    const [items, setItems] = React.useState([]);

    const makeItems = (arr) =>
    {
        let tempArr = [];
        for(let i = 0; i<10; i++)
        {
            tempArr.push(
                <div key={i} className="mosaic-item cursor" style={{backgroundImage: `url(${arr[i].src})`}} onClick={()=>props.set(i)}>
                    
                </div>

            )
        }
        setItems(tempArr)
    }

    React.useEffect(() => {
        console.log('ran mosaic')
        if(props.items.length>1)
        makeItems(props.items);
    }, [props.items])

    return (
        <div className="mosaic flex-row">
            {items}
        </div>
    )
}
