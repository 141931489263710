import React from 'react'

export const Story = () => {
    return (
        <div className="story-text shadow">
            On Monday, December 2, 2019, Charles Frederick Gill, passed away at age 70 after a valiant battle with colon cancer. He is survived by his father Frank Harry Gill; beloved and supportive wife, Carol Garnett; four children with (Elizabeth Gill), Grant, Graham, Krista (Gill) Stevens, and Angela (Gill) Anguiano; two stepsons with (Carol Garnett), Matthew and Andrew Garnett; four grandchildren, Tesha and Aidan Stevens, Abigail and Hannah Anguiano; and by sisters Frances (Gill) Oppertshauser and Cynthia (Gill) Anthony.  <br></br><br></br>
 
 Charles was born in Toronto, Ontario Canada, on May 5, 1949. He graduated from the University of Toronto in 1971 and began his business career as a Chartered Accountant. He worked for Peoples Jewelers Limited, CA, from 1973 to 1993 where he held the positions of Executive Vice President, CFO, and Director from 1986 - 1991, and President from (1991 - 1993). He led the successful tender offer for Zale Corporation (US) and became the Assistant to the President and a Director of Zales from 1986 - 1992 He was co-owner of the Jemms Group, CA, from 1993 - 1996. From 1996 until retirement in 2010  he was the Vice President, US Development, for the Royal Group International. This position created the opportunity for him to move to the United States.  He resided in St. Petersburg, FL, where he lived until his death.<br></br><br></br>
  
 A self-taught Duplicate Bridge player, he won the American Contact Bridge League’s national Mini-McKinney Bronze Life Masterpoints Race in 2004, and attained the rank of Sapphire Life Master before his death. His love of bridge coupled with his positive, friendly approach to business resulted in his development of the St. Petersburg Bridge Club over a fourteen year period. His vision was to unify a small group of separately owned games under one management creating one of the 50 largest bridge clubs in the United States. As President and Club Manager from 2005 until 2014 he raised more than $500,000 enabling the club to purchase its playing space and securing its future as a member-owned club. He remained Club Manager until his death. He was a member of the ACBL Board of Governors, and Florida’s District 9 and Unit 128 Board of Governors. He was chosen Volunteer of the Year by the ACBL in 2011.<br></br><br></br>
  
 While other interests included tennis, chess and curling. Charles really enjoyed his time at the family cottage. He loved when family and friends would get together there and have fun doing different activities such as the ATV`s and different water activities. Over the years Charles was actively involved in church work helping with children and teens and sitting on the board of deacons.<br></br><br></br>
  
 Charles was an avid supporter of children who were neglected, abused and abandoned. For more than a decade he served as a volunteer Guardian ad Litem serving children in Pinellas and Pasco Counties.<br></br><br></br>
  
 Charlie was an incurable optimist.  He reached hundreds of people daily throughout the world when he began to blog about his “colon cancer journey” in the spring of 2017. His <a href="https://charlescoloncancerjourney.blogspot.com/" target="_blank">blog</a> and recently published book, Red Shirts and Rubber Duckies, Surviving Cancer with a Smile, offers cancer patients, caregivers, support groups, relatives and friends insight and practical guidance seasoned with Charles’ special brand of gentle humor. <br></br><br></br>
  
  
 Optimism is the belief that "good things will happen to you and that negative events are temporary setbacks to be overcome." When one combines optimism with integrity you have "a person who seeks the best outcome in every situation, while being honest and forthright about the facts of situations as they exist."  To optimism and integrity add kindness, loyalty, friendliness, love of his fellow man and mix these qualities together with a large measure of whimsy and you have Charles Gill.<br></br><br></br>
  
 In celebration of Charlie’s life, The Charles Gill Memorial Game will be held on Saturday, March 14, 2020, at the St. Petersburg Bridge Club, 9103 U.S. Highway 19 N., Pinellas Park, FL 33782, Beginning at 5:00 PM there will be dinner, followed by tributes, special memories and bridge.<br></br><br></br>
  
 In Canada a Celebration of Life will be held April 25, 2020 at 1:00 PM at the First Baptist Church, 812 Hortop Street, Oshawa, Ontario, with a reception to follow.<br></br><br></br>
  
 Memorial Contributions may be made in Charlie’s name to the Guardian ad Litem Foundation of Tampa Bay, a nonprofit organization providing direct support to needy and neglected children in the local welfare system. Contributions can be made here:   <a href="https://www.herotoachild.org" target="_blank">herotoachild.org</a>.<br></br><br></br>
  
 In Canada Memorial Contributions may be made to Camp Kwasind, which is a Christian camp located on the same lake as the family cottage. The family spent a lot of quality time there and it meant a lot to Charles. Contributions can be made here:   <a href="http://kwasind.com/donate/" target="_blank">kwasind.com/donate/</a><br></br><br></br>
        </div>
    )
}
