import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import Viewer from 'react-viewer';
import { Header } from './Header';
import { Story } from './Story';
import { Mosaic } from './Mosaic';
import { Parallax, Background } from 'react-parallax';
import { Comments } from './comments';
import 'animate.css'



function App() {
  const [picNum, setPicNum] = useState(52)
  const [ visible, setVisible ] = React.useState(false);
  const [ activeIndex, setActiveIndex ] = React.useState(0);
  const [ picArray, setPicArray ] = React.useState([]);
  const[top, setTop] = React.useState(0);
  let picCount = 50

  useEffect(()=>
  {
    let counter = 59
    let flag = true

    
    getRequireLength(50)

    try{
      makeImgArray()
    }catch{
      console.log('opps')
    }
    
    
  },[])

  const getRequireLength = (i) => {
    try {
      require(`./assets/${i}pic.jpg`)
      getRequireLength(i+1)
    }catch{
      
      picCount = i
      console.log('pic count should be 59:',picCount)
    }
  }

  const picChange =(dir)=>
  {
    switch (dir){
      case 'up':
        if(picNum===picCount){
          setPicNum(1)
        }else{
          setPicNum(picNum+1)
        }
        break;
      case 'down':
        if(picNum===1){
          setPicNum(picCount)
        }else{
          setPicNum(picNum-1)
        }
        break;
    }
  }

  const makeImgArray = () => {
    let arr = []
    for(let i = 1; i<picCount; i++)
    if(require(`./assets/${i}pic.jpg`))
    {
      arr.push({src: `${require(`./assets/${i}pic.jpg`)}`, alt: ''})
    }
    setPicArray(arr);

  }

  const openAt =(i)=>{
    setVisible(true);
    setActiveIndex(i);
  }

  return (
    
    <div className="App flex">
      <Header set={openAt}/>
      
      <div className='parallax' style={{top:top}}></div>
      

      
      
      
    
      <div className="main-pic flex shadow animated fadeInLeft slow" style={{
        backgroundImage: `url(${require(`./assets/${picNum}pic.jpg`)})`
        // backgroundImage: `url(https://images.unsplash.com/photo-1534142499731-a32a99935397?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1834&q=80)`
      }}></div>
      <Story/>
      <div>
        
        <Viewer
        visible={visible}
        onClose={() => { setVisible(false); } }
        images={picArray}
        activeIndex={activeIndex}
        attribute={false}
        drag={false}
        />
    </div>
    <Mosaic items={picArray} set={openAt}/>
    <Comments/>
    <div className="footer"></div>
    </div>
    
  );
}

export default App;
