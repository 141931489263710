import React from 'react'

export const Header = (props) => {
    return (
        <div className="header flex-row apart">
            <div className="header-title">Charles Gill</div>
            <div className="tabs flex-row">
                <div className="tab cursor flex-row" onClick={()=>props.set(0)}>
                    <i className="material-icons" style={{fontSize:'1.5rem', margin: '4px'}}>
                    photo
                    </i>
                    <div className="tab-name">Pictures</div> 
                </div>
                <a href="https://www.amazon.com/Red-Shirts-Rubber-Duckies-Surviving/dp/1684562481" target="_blank" className="tab cursor flex-row">
                    <i className="material-icons" style={{fontSize:'1.5rem', margin: '4px'}}>
                    menu_book
                    </i>    
                    <div className="tab-name">Charles' Book</div> 
                </a>
            </div>
            
            
            
        
        </div>
    )
}
