import React, { useState } from 'react'
import firebase from './firebase'
import moment from 'moment'



export const Comments = () => {
    const [comments, setComments] = useState([]);
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const getComments = () => {
        let temp = []
        firebase.firestore().collection('comments').get().then(d=>{
            
            d.docs.forEach((e,i)=>{
                console.log(e.data())
                temp.push(
                    <div className="comment flex animated fadeIn" key={i}>
            <div className="comment-user">{e.data().username.toUpperCase()} <span>wrote on {e.data().date}</span></div>
            <div className="comment-body">{e.data().comment}</div>
                    </div>
                )
            })
            setComments(temp);
        })
    }
    React.useEffect(()=>{
        getComments();
    },[])

    const submit = (n,m) => {
        firebase.firestore().collection('comments').add({
            username: n,
            comment: m,
            date: moment().format("MMM Do YYYY")
        }).then(d=>{
            getComments()
            let element = document.querySelector('.comments');
            element.scrollIntoView({behavior:'smooth'})
        })
    }
    return (
        <div className="comments flex">
            <h1 className="title">Comments</h1>
            {comments}
            
            <div className="add-comment flex">
                <h1 className="title">Add Comment</h1>
                <input type="text" className="" name="name" placeholder="Your name" value={name} onChange={e=>setName(e.currentTarget.value)}/>
                <textarea name="message" id="textarea" cols="30" rows="10" placeholder="Your message" value={message} onChange={e=>setMessage(e.currentTarget.value)}></textarea>
                <div className="btn flex" onClick={()=>submit(name,message)}>Submit</div>
            </div>
            <h1 className="banner">We would love to have any photos that you may have to add to this site. Please email them to <a href="mailto:kristastevens2030@gmail.com">kristastevens2030@gmail.com</a></h1>

            
        </div>
    )
}
