import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyCBB_S7sMeCXpV_ICKDbGlWIkg1UvgvZRc",
    authDomain: "obit-64be9.firebaseapp.com",
    databaseURL: "https://obit-64be9.firebaseio.com",
    projectId: "obit-64be9",
    storageBucket: "obit-64be9.appspot.com",
    messagingSenderId: "111651671431",
    appId: "1:111651671431:web:fb3a59426edffd6f199722",
    measurementId: "G-NX3Z6YD5W4"
  };

  firebase.initializeApp(firebaseConfig);


  export default firebase;